<template>
	<div class="vf-file-wrapper" @click="openUrl">
		<vf-file-vector :text="file_extension" />
		<div class="file-name">{{file_name}}</div>
		<div class="vf-file-wrapper-hover">
			<div class="hover-icon"><i class="fas fa-download"></i></div>
			<div class="hover-text">Download</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		file_name: String,
		url: String,
	},
	computed: {
		file_extension() {
			if (this.file_name == undefined) return "---"
			return this.file_name.split(/\./).pop().toUpperCase();
		}
	},
	methods: {
		openUrl() {
			window.open(this.url, "_blank");
		}
	}
}
</script>

<style lang="scss" scoped>
.vf-file-wrapper {
	border: 1px solid #c7c9d9;
	box-sizing: border-box;
	border-radius: 12px;
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	width: 137px;
	height: 137px;

	.file-name {
		margin-top: 16px;
		font-size: 12px;
		line-height: 22px;
		text-align: center;
		color: #252733;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 10px;
		white-space: nowrap;
	}

	.vf-file-wrapper-hover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #00000099;
		color: #fff;
		opacity: 0;
		transition: opacity 200ms ease;

		.hover-icon {
			font-size: 25px;
		}

		.hover-text {
			margin-top: 12px;
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
		}
	}

	&:hover .vf-file-wrapper-hover {
		opacity: 1;
	}
}
</style>