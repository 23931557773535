<template>
	<div class="panel-content panel-card">
		<div class="view-project-content-wrapper mt-3">
			<vf-loading class="mx-auto mt-3" v-if="loading.project"></vf-loading>

			<div class="view-project-content" v-else>
				<div class="row gy-4">
					<div class="col-12 col-md-6">
						<div class="view-project-content-row">
							<div class="view-project-content-title tg-heading-5">Project Title:</div>
							<div class="view-projet-content-value mt-3">
								{{project_data.title}}
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="view-project-content-row">
							<div class="view-project-content-title tg-heading-5">Project Worth:</div>
							<div class="view-projet-content-value mt-3">
								{{project_data.worth}}
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="view-project-content-row">
							<div class="view-project-content-title tg-heading-5">Support Percentage:</div>
							<div class="view-projet-content-value mt-3">
								{{project_data.support_percentage}}%
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="view-project-content-row">
							<div class="view-project-content-title tg-heading-5">Project Description:</div>
							<div class="view-projet-content-value mt-3">
								{{project_data.description}}
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="view-project-content-row">
							<div class="view-project-content-title tg-heading-5">Attached Files:</div>
							<div class="view-projet-content-value mt-3">
								<div class="project-files">
									<div class="file-item" v-for="(file , j) in project_data.project_files" :key="j">
										<vf-file-wrapper :file_name="file.title" :url="file.link" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="view-project-btns d-flex justify-content-center">
				<vf-btn @click="$parent.viewProject.show = false">Back to projects</vf-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading: {
				project: false,
			},
			project_data : {},
		}
	},
	methods:{
		getProject() {
			/* call the api */
			this.loading.project = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("project_id", this.$parent.viewProject.project_id);

			/* make call to server */
			this.$http.post("admin/projects/getProject", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.project_data = result.data.data.project;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.project = false;
				})
		}
	},
	created() {
		this.getProject();
	}
}
</script>

<style lang="scss">
.view-project-content-wrapper {
	.view-project-content {
		width: 100%;

		.project-files{
			display: flex;
			flex-wrap: wrap;
			margin: -10px;

			.file-item{
				padding: 10px;
			}
		}
	}

	.view-project-btns {
		margin-top: 32px;
	}
}
</style>