<template>
	<vf-modal class="remove-user" v-model="$parent.removeUser.show">
		<div class="remove-user-header">
			<button class="close-btn" @click="$parent.removeUser.show = false">
				<i class="fas fa-times"></i>
			</button>
			<div class="remove-user-title">Remove User</div>
		</div>

		<div class="remove-user-content">
			<div>
				Are you sure you want to remove this user?
			</div>

			<div class="remove-btns d-flex">
				<vf-btn class="remove-user-remove-btn" type="primary" :loading="loadingSuspendUser" @click.prevent="suspendUser">
					Remove User
				</vf-btn>
				<vf-btn class="remove-user-cancel-btn" type="outline" @click.prevent="$parent.removeUser.show = false">
					Cancel
				</vf-btn>
			</div>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			loadingSuspendUser: false,
		}
	},
	methods:{
		suspendUser(){
			/* call the api */
			this.loadingSuspendUser = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", this.$parent.removeUser.user_id);
			data.append("suspend", 1);

			/* make call to server */
			this.$http.post("admin/users/suspendUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.removeUser.show = false;
						this.$parent.getUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loadingSuspendUser = false;
				})
		}
	}
}
</script>

<style lang="scss">
.remove-user {
	.remove-user-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.remove-user-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.remove-user-content {
		margin-top: 24px;

		.remove-btns {
			margin-top: 32px;

			.remove-user-remove-btn {
				background: #e53935;

				&:hover {
					background: #f44336;
				}
			}

			.remove-user-cancel-btn {
				margin-left: 16px;
				border-color: #e53935;
				color: #e53935;
			}
		}
	}
}
</style>