import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'
import Logout from '../views/Logout.vue'
import LayoutPanel from '../views/Layouts/LayoutPanel.vue'

/* panel */
import Dashboard from '../views/Panel/Dashboard/Dashboard.vue'
import Transactions from '../views/Panel/Transactions.vue'
import Withdraw from '../views/Panel/Withdraws/Withdraws.vue'
import Settings from '../views/Panel/Settings/Settings.vue'
import Tickets from '../views/Panel/Support/Tickets.vue'
import Projects from '../views/Panel/Projects/Projects.vue'
import Users from '../views/Panel/Users/Users.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/panel',
    name: 'panel',
    component: LayoutPanel,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'projects',
        name: 'Projects',
        component: Projects
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: Transactions
      },
      {
        path: 'withdraw',
        name: 'Withdraw',
        component: Withdraw
      },
      {
        path: 'chat',
        name: 'Tickets',
        component: Tickets
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
