<template>
	<vf-modal class="edit-withdraw" v-model="$parent.editWithdraw.show">
		<div class="edit-withdraw-header">
			<button class="close-btn" @click="$parent.editWithdraw.show = false">
				<i class="fas fa-times"></i>
			</button>
			<div class="edit-withdraw-title">Edit Withdraw</div>
		</div>

		<div class="edit-withdraw-content">
			<form class="edit-withdraw-form" @submit.prevent="editWithdrawAction">
				<div class="form-row">
					<vf-select-input label="New Status" placeholder="Select Status" :selectOptions="withdrawStatuses" v-model="editWithdraw.newStatus" />
				</div>
				<div class="form-row">
					<vf-input label="TXID" id="edit-withdraw-amount" type="text" v-model="editWithdraw.newTxid" />
				</div>
				<div class="form-btns d-flex">
					<vf-btn class="edit-withdraw-create-btn" type="primary" :loading="loading.editWithdraw">
						Edit Withdraw
					</vf-btn>
					<vf-btn class="edit-withdraw-cancel-btn" type="outline" @click.prevent="$parent.editWithdraw.show = false">
						Cancel
					</vf-btn>
				</div>
			</form>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			loading:{
				editWithdraw: false,
			},
			withdrawStatuses: [
				{ title: 'Pending', value: 'pending' },
				{ title: 'Ongoing', value: 'ongoing' },
				{ title: 'Finished', value: 'finished' },
			],
			editWithdraw: {
				newStatus: null,
				newTxid: null,
			}
		}
	},
	methods: {
		resetForm() {
			this.editWithdraw = {
				newStatus: null,
				newTxid: null,
			}
		},
		editWithdrawAction(){
			/* call the api */
			this.loading.editWithdraw = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("withdraw_id", this.$parent.editWithdraw.withdrawData.id);

			if(this.editWithdraw.newStatus != null){
				data.append("status", this.editWithdraw.newStatus.value);
			}
			
			if(this.editWithdraw.newTxid != null){
				data.append("txid", this.editWithdraw.newTxid);
			}

			/* make call to server */
			this.$http.post("admin/transactions/updateWithdraw", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.resetForm();
						this.$parent.editWithdraw.show = false;
						this.$parent.getWithdrawHistory();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.editWithdraw = false;
				})
		}
	},
	watch: {
		'$parent.editWithdraw.show': function (newVal) {
			if (newVal == true) {
				this.resetForm();

				this.editWithdraw.newTxid = this.$parent.editWithdraw.withdrawData.txid;
			}
		}
	}
}
</script>

<style lang="scss">
.edit-withdraw {
	.edit-withdraw-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.edit-withdraw-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.edit-withdraw-content {
		margin-top: 24px;

		.edit-withdraw-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-btns {
				margin-top: 32px;
			}

			.edit-withdraw-send-btn {
				margin-top: 28px;
			}

			.edit-withdraw-cancel-btn {
				margin-left: 16px;
				border-color: #4040f2;
				color: #4040f2;
			}
		}
	}
}
</style>