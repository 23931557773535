<template>
	<div class="panel-content-wrapper users-panel-bar-wrapper">
		<div class="panel-bar-wrapper users-panel-bar">
			<div class="left">
				<vf-search-input v-model="searchQ" @search="getUsers" />
			</div>

			<vf-spacer />

			<div class="right">
				<vf-btn type="primary" @click="showCreateUser">
					<i class="fas fa-plus btn-icon"></i> Create New User
				</vf-btn>
			</div>
		</div>

		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="usersLoading" />

			<div class="panel-content users" v-else>
				<div class="users-content">
					<vf-table>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Username</th>
							<th>Email</th>
							<th>Wallet / Freezed</th>
							<th>Signup Date</th>
							<th style="width: 50px;"></th>
						</tr>

						<tr v-for="(item , i) in users.rows" :key="i">
							<td>{{item.id}}</td>
							<td>{{item.name}}</td>
							<td>{{item.username}}</td>
							<td>{{item.email}}</td>
							<td>{{item.current_wallet}} / {{item.freezed_wallet}}</td>
							<td>{{item.human_created_at}}</td>
							<td>
								<vf-btn class="view-user-btn" type="outline" @click="showViewUser(item.id)"><i class="fas fa-eye"></i></vf-btn>
								<vf-btn class="make-transaction-btn ms-2" type="outline" @click="showMakeTransaction(item.id)"><i class="fas fa-money-bill-wave"></i></vf-btn>
								<vf-btn class="edit-user-btn ms-2" type="outline" @click="showEditUser(item.id)"><i class="fas fa-pencil"></i></vf-btn>
								<vf-btn class="remove-user-btn ms-2" type="outline" @click="showRemoveUser(item.id)"><i class="fas fa-trash"></i></vf-btn>
							</td>
						</tr>

					</vf-table>
				</div>
			</div>
		</div>

		<MakeTransaction />
		<CreateUser />
		<EditUser />
		<RemoveUser />
		<ViewUser />
	</div>
</template>

<script>
import MakeTransaction from "./MakeTransaction"
import CreateUser from "./CreateUser"
import EditUser from "./EditUser"
import RemoveUser from "./RemoveUser"
import ViewUser from "./ViewUser"


export default {
	components: {
		MakeTransaction,
		CreateUser,
		EditUser,
		RemoveUser,
		ViewUser
	},
	data() {
		return {
			usersLoading: false,
			users: {
				page: 1,
				total_count: 0,
				total_pages: 1,
				rows: []
			},
			currentPage: 1,
			searchQ: '',
			suspendLoading: false,
			suspendLoadingIndex: 0,
			editUser: false,
			editUserData: {},

			createUser: {
				show: false,
			},
			viewUser: {
				user_id: null,
				show: false,
			},
			editUser: {
				user_id: null,
				show: false,
			},
			removeUser: {
				user_id: null,
				show: false,
			},
			makeTransaction: {
				user_id: null,
				show: false,
			}
		}
	},
	methods: {
		showCreateUser() {
			this.createUser.show = true;
		},
		showMakeTransaction(user_id) {
			this.makeTransaction.user_id = user_id;
			this.makeTransaction.show = true;
		},
		showViewUser(user_id) {
			this.viewUser.user_id = user_id;
			this.viewUser.show = true;
		},
		showEditUser(user_id) {
			this.editUser.user_id = user_id;
			this.editUser.show = true;
		},
		showRemoveUser(user_id) {
			this.removeUser.user_id = user_id;
			this.removeUser.show = true;
		},
		getUsers() {
			/* call the api */
			this.usersLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/users/getAllUsers", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.users = result.data.data.users;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.usersLoading = false;
				})
		},
		searchUsers() {
			this.currentPage = 1;
			this.getUsers();
		},
	},
	created() {
		this.getUsers();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Users");
	}
}
</script>

<style lang="scss">
@import "../../../assets/styles/_variables";

.users-panel-bar-wrapper {
	.users-panel-bar {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.left {
			display: flex;
			align-items: center;

			@include only-phone {
				width: 100%;
				margin-bottom: 10px;

				.vf-search-input-form {
					width: 100%;
				}
			}
		}

		.right {
			display: flex;
			align-items: center;

			@include only-phone {
				width: 100%;
			}
		}
	}
}

.view-user-btn {
	background-color: #bbdefb !important;

	&:hover {
		background-color: #90caf9 !important;
	}
}

.make-transaction-btn {
	background-color: #bdfbbb !important;

	&:hover {
		background-color: #93f990 !important;
	}
}

.edit-user-btn {
	background-color: #f5f5f5 !important;

	&:hover {
		background-color: #dddddd !important;
	}
}

.remove-user-btn {
	background-color: #ffcdd2 !important;

	&:hover {
		background-color: #ef9a9a !important;
	}
}
</style>