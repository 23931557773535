const state = {
	admin_id: localStorage.getItem("admin_id") || '',
	admin_token: localStorage.getItem("admin_token") || '',
	admin_name: localStorage.getItem("admin_name") || '',
	admin_profile_pic_url: localStorage.getItem("admin_profile_pic_url") || '',
};


const getters = {
	isAdminLoggedIn(state) {
		if (state.admin_token == "" || state.admin_token == null || state.admin_token == undefined) {
			return false;
		} else {
			return true;
		}
	}
};

const mutations = {
	setAdminData(state, adminData) {
		state.admin_id = adminData.id;
		state.admin_token = adminData.token;
		state.admin_name = adminData.name;
		state.admin_profile_pic_url = adminData.profile_pic_url;


		/* set local storage */
		if (adminData.store == true) {
			if(adminData.id != null){
				localStorage.setItem('admin_id', adminData.id);
			}
			if(adminData.token != null){
				localStorage.setItem('admin_token', adminData.token);
			}
			if(adminData.name != null){
				localStorage.setItem('admin_name', adminData.name);
			}
			if(adminData.profile_pic_url != null){
				localStorage.setItem('admin_profile_pic_url', adminData.profile_pic_url);
			}
		}

	},
	updateAdminData(state, adminData) {
		if (adminData.id != undefined) state.admin_id = adminData.id;
		if (adminData.token != undefined) state.admin_token = adminData.token;
		if (adminData.name != undefined) state.admin_name = adminData.name;
		if (adminData.profile_pic_url != undefined) state.admin_profile_pic_url = adminData.profile_pic_url;


		/* set local storage*/
		if (localStorage.getItem("admin_id") != undefined) {
			if (adminData.id != undefined) localStorage.setItem('admin_id', adminData.id);
			if (adminData.token != undefined) localStorage.setItem('admin_token', adminData.token);
			if (adminData.name != undefined) localStorage.setItem('admin_name', adminData.name);
			if (adminData.profile_pic_url != undefined) localStorage.setItem('admin_profile_pic_url', adminData.profile_pic_url);
		}
	},
	unsetAdminData(state) {
		state.id = '';
		state.admin_token = '';
		state.admin_name = '';
		state.admin_profile_pic_url = '';


		/* set local storage */
		localStorage.removeItem('admin_id');
		localStorage.removeItem('admin_token');
		localStorage.removeItem('admin_name');
		localStorage.removeItem('admin_profile_pic_url');
	},

};

const actions = {
	loginAdmin(context, creds) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("username", creds.username);
			data.append("password", creds.password);

			/* make call to server */
			this._vm.$http.post("admin/admins/loginAdmin", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setAdminData', {
						id: result.data.data.admin.id,
						token: result.data.data.admin.token,
						name: result.data.data.admin.name,
						profile_pic_url: result.data.data.admin.profile_pic_url,
						store: creds.store == true
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	},
	logoutAdmin(context) {
		context.commit('unsetAdminData');
	},
	updateAdminData(context) {
		return new Promise((resolve, reject) => {
			/* make data */
			var data = new FormData();
			data.append("token", context.state.admin_token);

			/* make call to server */
			this._vm.$http.post("admin/admins/authToken", data).then((result) => {
				if (result.data.status == "ok") {
					context.commit('setAdminData', {
						id: result.data.data.admin.id,
						token: result.data.data.admin.token,
						name: result.data.data.admin.name,
						profile_pic_url: result.data.data.admin.profile_pic_url,
					});
					resolve(result.data.data);
				} else {
					reject(result.data.error);
				}
			}).catch(function (error) {
				reject(error);
			});
		})
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
