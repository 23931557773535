<template>
	<vf-modal class="edit-user" v-model="$parent.editUser.show">
		<div class="edit-user-header">
			<button class="close-btn" @click="$parent.editUser.show = false">
				<i class="fas fa-times"></i>
			</button>
			<div class="edit-user-title">Edit User</div>
		</div>

		<div class="edit-user-content">
			<form class="edit-user-form" @submit.prevent="editUser">
				<div class="form-row">
					<vf-input label="Email" id="edit-user-email" type="email" v-model="editUserData.email" />
				</div>
				<div class="form-row">
					<vf-input label="Name" id="edit-user-name" type="text" v-model="editUserData.name" />
				</div>
				<div class="form-row">
					<vf-input label="Password" id="edit-user-pass" type="password" v-model="editUserData.password" />
				</div>
				<div class="form-btns d-flex">
					<vf-btn class="edit-user-edit-btn" type="primary" :loading="loading.editUser">
						Edit User
					</vf-btn>
					<vf-btn class="edit-user-cancel-btn" type="outline" @click.prevent="$parent.editUser.show = false">
						Cancel
					</vf-btn>
				</div>
			</form>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				editUser: false,
			},
			editUserData: {
				email: '',
				name: '',
				password: '',
			}
		}
	},
	methods: {
		resetForm() {
			this.editUserData = {
				email: '',
				name: '',
				password: '',
			};
		},
		editUser() {
			/* call the api */
			this.loading.editUser = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", this.$parent.editUser.user_id);

			if (this.editUserData.name != '') {
				data.append("name", this.editUserData.name)
			}

			if (this.editUserData.email != '') {
				data.append("email", this.editUserData.email)
			}

			if (this.editUserData.password != '') {
				data.append("password", this.editUserData.password)
			}

			/* make call to server */
			this.$http.post("admin/users/updateUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.resetForm();

						this.$parent.editUser.show = false;
						this.$parent.getUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.editUser = false;
				})
		}
	}
}
</script>

<style lang="scss">
.edit-user {
	.edit-user-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.edit-user-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.edit-user-content {
		margin-top: 24px;

		.edit-user-form {
			margin-top: 28px;
			width: 100%;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-btns {
				margin-top: 32px;
			}

			.edit-user-send-btn {
				margin-top: 28px;
			}

			.edit-user-cancel-btn {
				margin-left: 16px;
				border-color: #4040f2;
				color: #4040f2;
			}
		}
	}
}
</style>