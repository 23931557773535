<template>
	<div class="login-wrapper">
		<div class="login-content-wrapper">
			<div class="login-content">
				<h4 class="login-title">Admin Login</h4>
				<div class="login-desc">Continue to website.</div>

				<form class="login-form" @submit.prevent="login()">
					<vf-input id="username-input" class="" placeholder="Username" type="username" v-model="creds.username"></vf-input>
					<vf-input id="password-input" class="mt-4" placeholder="Password" type="password" v-model="creds.password"></vf-input>

					<div class="login-bottom mt-4">
						<div class="login-remember">
							<vf-checkbox text="Remember me" v-model="creds.remember"></vf-checkbox>
						</div>
					</div>

					<div class="login-button mt-4">
						<button id="login-btn">
							<vf-loading class="mx-auto" color="#fff" :size="20" :thickness="2" v-if="loading" />
							<span v-else>Login</span>
						</button>
					</div>
				</form>
			</div>
		</div>

		<div class="login-spacefiller">
			<img class="login-logo" src="../../assets/pics/logo.png">
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading: false,
			creds: {
				username: '',
				password: '',
				remember: false,
			}
		}
	},
	methods: {
		login() {
			this.loading = true;
			this.$store.dispatch('loginAdmin', {
				username: this.creds.username,
				password: this.creds.password,
				store: this.creds.remember
			})
				.then(() => {
					this.$swal.fire({
						icon: "success",
						title: 'Welcome!',
						timer: 1000,
						timerProgressBar: true,
						showConfirmButton: false,
						toast: true,
						position: 'top-end',
					}).then(() => {
						this.$router.push('/panel/dashboard');
					})
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading = false;
				})
		}
	},
	created() {
		if (this.$store.getters.isAdminLoggedIn) {
			this.$router.push('/panel/dashboard');
			return;
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/_variables.scss";

.login-wrapper {
	display: flex;
	height: 100%;

	.login-spacefiller {
		width: 700px;
		height: 100%;
		background: black;
		position: relative;
		border-radius: 50px 0px 0px 50px;
		background-image: url("../../assets/pics/login-background.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@include only-phone-lg {
			display: none;
		}

		.login-logo {
			position: absolute;
			width: 70px;
			top: 100px;
			right: 100px;
		}
	}

	.login-content-wrapper {
		flex: 1;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		@include only-phone-lg {
			padding: 0px 26px;
		}

		.login-content {
			width: 100%;
			max-width: 500px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.login-title {
				font-size: 32px;
				line-height: 39px;
				letter-spacing: 0.2px;
				color: #000000;
				font-weight: 700;
				width: 100%;
			}

			.login-desc {
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.2px;
				color: #8f90a6;
				margin-bottom: 40px;
				width: 100%;
				margin-top: 12px;
			}

			.login-form {
				width: 100%;

				.login-bottom {
					display: flex;
					align-items: center;

					.login-remember {
					}

					.login-forget {
						margin-left: auto;

						#forget-btn {
							background: none;
						}
					}
				}

				#login-btn {
					background: #000000;
					color: #ffffff;
					width: 100%;
					border-radius: 15px;
					transition: all 0.2s ease-in-out;
					height: 45px;
					padding: 0 24px;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.2px;
					display: block;
					position: relative;

					&:hover {
						background: #2b2b2b;
					}
				}
			}
		}

		.login-create-account {
			span {
				color: #8f90a6;
				margin-right: 8px;
			}

			button {
				background: none;
			}
		}
	}
}
</style>