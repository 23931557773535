<template>
	<div class="panel-content skills">
		<div class="skills-head">
			<h5 class="skills-title tg-heading-5">Skills</h5>
		</div>

		<vf-loading class="mx-auto my-5" v-if="loading.skills" />

		<form class="skills-form mt-3" @submit.prevent="setSkills" v-else>
			<vf-textarea id="skills-input" placeholder="Put each skill in new line" rows="6" v-model="skills" />
			<vf-btn class="mt-3 save-btn" type="primary" :loading="loading.setSkills">Set Skills</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading:{
				skills: false,
				setSkills: false,
			},
			skills: '',
		}
	},
	methods: {
		getSkills() {
			/* call the api */
			this.loading.skills = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/general/getSkills", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.skills = result.data.data.skills.join('\n');
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.skills = false;
				})
		},
		setSkills() {
			/* call the api */
			this.loading.setSkills = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			if(this.skills == ''){
				data.append("skills", '');
			} else {
				/* remove empty spaces */
				data.append("skills", this.skills.trim().replace(/\n/g, ","));
			}

			/* make call to server */
			this.$http.post("admin/settings/updateSkills", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.getSkills();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.setSkills = false;
				})
		}
	},
	created(){
		this.getSkills();
	}
}
</script>

<style>
</style>