<template>
	<div class="vf-section-card">
		<div class="vf-section-card-header">
			<div class="vf-section-card-header-left">
				<slot name="header-left"></slot>
			</div>

			<div class="vf-section-card-header-right">
				<slot name="header-right"></slot>
			</div>
		</div>

		<div class="vf-section-card-content">
			<slot></slot>
		</div>

		<div class="vf-section-card-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.vf-section-card {
	background: #ffffff;
	border-radius: 12px;
	padding: 20px 32px;
	display: flex;
	flex-direction: column;

	@include only-phone-md {
		padding: 16px;
	}

	.vf-section-card-header {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		.vf-section-card-header-left {
			display: flex;
			align-items: center;
			
			.vf-section-card-header-title{
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #1c1c28;
	
				@include only-phone-lg {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}

		.vf-section-card-header-right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}
	}

	.vf-section-card-content {
		flex: 1;
		overflow-y: auto;
		padding: 0 32px;
		margin: 0 -32px;

		@include only-phone-md {
			padding: 0 16px;
			margin: 0 -16px;
		}
	}
}
</style>