<template>
	<div class="panel-content-wrapper dashboard-wrapper">
		<div class="panel-content-container dashboard">
			<vf-loading class="mx-auto mt-3" v-if="loading.dashboard" />

			<template v-else>
				<SmallBoxes />

				<div class="row">
					<div class="col-12 col-md-6">
						<div class="panel-content panel-card dashboard-card mt-4">
							<div class="panel-head">
								<h5 class="projects-panel-title tg-heading-5">Recent Tickets</h5>
							</div>

							<div class="projects-panel-content mt-3">
								<vf-table>
									<tr>
										<th>User</th>
										<th>Title</th>
										<th>Status</th>
										<th>Date</th>
									</tr>

									<tr v-for="(item ,i) in dashboard.last_tickets" :key="i">
										<td>{{item.user_name}}</td>
										<td>{{item.title}}</td>
										<td>
											<vf-badge class="bg-green fg-white d-block text-center" v-if="item.status == 'open'">
												Open
											</vf-badge>

											<vf-badge class="bg-blue fg-white d-block text-center" v-else-if="item.status == 'admin_answered'">
												Support Answered
											</vf-badge>

											<vf-badge class="bg-cyan fg-white d-block text-center" v-else-if="item.status == 'user_answered'">
												User Answered
											</vf-badge>

											<vf-badge class="bg-grey fg-white d-block text-center" v-else-if="item.status == 'closed'">
												Closed
											</vf-badge>
										</td>
										<td>{{item.human_created_at}}</td>
									</tr>
								</vf-table>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6">
						<div class="panel-content panel-card dashboard-card mt-4">
							<div class="panel-head">
								<h5 class="projects-panel-title tg-heading-5">Recent Projects</h5>
							</div>

							<div class="projects-panel-content mt-3">
								<vf-table>
									<tr>
										<th>Project Title</th>
										<th>Project Worth</th>
										<th>Status</th>
										<th>Date</th>
									</tr>

									<tr v-for="(item , i) in dashboard.last_projects" :key="i">
										<td>{{item.title}}</td>
										<td>{{item.worth}}</td>
										<td>
											<vf-badge class="bg-orange fg-white" v-if="item.status == 'pending'">
												Pending
											</vf-badge>

											<vf-badge class="bg-green fg-white" v-else-if="item.status == 'accepted'">
												Accepted
											</vf-badge>

											<vf-badge class="bg-red fg-white" v-else-if="item.status == 'rejected'">
												Rejected
											</vf-badge>

											<vf-badge class="bg-blue fg-white" v-else-if="item.status == 'finished'">
												Finished
											</vf-badge>
										</td>
										<td>{{item.human_created_at}}</td>
									</tr>
								</vf-table>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="panel-content panel-card dashboard-card mt-4">
							<div class="panel-head">
								<h5 class="projects-panel-title tg-heading-5">Recent Users</h5>
							</div>

							<div class="projects-panel-content mt-3">
								<vf-table>
									<tr>
										<th>#</th>
										<th>Name</th>
										<th>Username</th>
										<th>Email</th>
										<th>Date</th>
									</tr>

									<tr v-for="(item , i) in dashboard.last_users" :key="i">
										<td>{{item.id}}</td>
										<td>{{item.name}}</td>
										<td>{{item.username}}</td>
										<td>{{item.email}}</td>
										<td>{{item.human_created_at}}</td>
									</tr>
								</vf-table>
							</div>
						</div>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
import SmallBoxes from "./SmallBoxes"

export default {
	components: {
		SmallBoxes,
	},
	data() {
		return {
			loading: {
				dashboard: false,
			},
			dashboard: {
				total_projects_count: null,
				total_users_count: null,
				users_total_wallet: null,
				users_total_freezed_wallet: null,
				last_projects: [],
				last_tickets: [],
				last_users: [],
			},
		}
	},
	methods: {
		getDashboard() {
			/* call the api */
			this.loading.dashboard = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/general/dashboard", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.dashboard = result.data.data
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.dashboard = false;
				})
		},
	},
	created() {
		this.getDashboard();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Dashboard");
	}
}
</script>

<style lang="scss">
.dashboard-wrapper {
	.dashboard {
		.projects-panel {
			.projects-panel-head {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}

.text-center {
	text-align: center;
}

.panel-card {
	&.dashboard-card{
		height: 430px;
		overflow-y: auto;
	}

	.panel-card-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
</style>