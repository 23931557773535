<template>
	<div class="panel-content apiInfo">
		<div class="apiInfo-head">
			<h5 class="apiInfo-title tg-heading-5">Bixb Api</h5>
		</div>

		<vf-loading class="mx-auto my-5" v-if="loading.apiInfo" />

		<form class="apiInfo-form mt-3" @submit.prevent="setApiInfo" v-else>
			<vf-input id="api-key-input" label="Api key:" v-model="apiInfo.apiKey" />
			<vf-input class="mt-3" id="secret-input" label="Secret:" v-model="apiInfo.secret" />
			<vf-btn class="mt-3 save-btn" type="primary" :disabled="apiInfo.apiKey == '' || apiInfo.secret == ''" :loading="loading.setApiInfo">Set Data</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				apiInfo: false,
				setApiInfo: false,
			},
			apiInfo: {
				apiKey: '',
				secret: ''
			},
		}
	},
	methods: {
		getApiInfo() {
			/* call the api */
			this.loading.apiInfo = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/settings/getApiInfo", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.apiInfo.apiKey = result.data.data.apiInfo.api_key;
						this.apiInfo.secret = result.data.data.apiInfo.secret;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.apiInfo = false;
				})
		},
		setApiInfo() {
			/* call the api */
			this.loading.setApiInfo = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("apiKey", this.apiInfo.apiKey.trim());
			data.append("secret", this.apiInfo.secret.trim());

			/* make call to server */
			this.$http.post("admin/settings/updateApiInfo", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.getApiInfo();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.setApiInfo = false;
				})
		}
	},
	created() {
		this.getApiInfo();
	}
}
</script>

<style>
</style>