<template>
	<div class="panel-content panel-card">
		<div class="panel-head">
			<h5 class="tg-heading-5">Edit Project</h5>
		</div>

		<div class="edit-project-content-wrapper mt-3">
			<div class="edit-project-content">
				<vf-select-input label="Status" placeholder="Select Status" :selectOptions="projectStatuses" v-model="new_status" />

				<div class="edit-project-btns d-flex">
					<vf-btn class="edit-project-edit-btn" type="primary" :loading="loading.editProject" @click.prevent="editProject">
						Edit Project
					</vf-btn>

					<vf-btn class="edit-project-cancel-btn" type="outline" @click.prevent="$parent.editProject.show = false">
						Cancel
					</vf-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			projectStatuses: [
				{ title: 'Pending', value: 'pending' },
				{ title: 'Accepted', value: 'accepted' },
				{ title: 'Rejected', value: 'rejected' },
				{ title: 'Finished', value: 'finished' },
			],
			loading: {
				editProject: false,
			},
			new_status: null,
		}
	},
	methods: {
		resetForm() {
			this.new_status = null;
		},
		editProject() {
			/* call the api */
			this.loading.editProject = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("project_id", this.$parent.editProject.project_id);
			data.append("status", this.new_status.value);

			/* make call to server */
			this.$http.post("admin/projects/editProject", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.editProject.show = false;
						this.$parent.getProjects();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.editProject = false;
				})
		}
	},
	created() {
		this.resetForm();
	}
}
</script>

<style lang="scss">
.edit-project-content-wrapper {
	.edit-project-content {
		width: 100%;
		max-width: 600px;

		.edit-project-btns {
			margin-top: 32px;

			.edit-project-edit-btn {
				margin-right: 24px;
			}
		}
	}
}
</style>