<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="loading.projects"></vf-loading>

			<template v-else>
				<div class="panel-content projects" v-if="!editProject.show && !viewProject.show">
					<div class="projects-content">
						<vf-table>
							<tr>
								<th>#</th>
								<th>User</th>
								<th>Project Title</th>
								<th>Project Worth</th>
								<th>Status</th>
								<th>Create Date</th>
								<th style="width: 50px;"></th>
							</tr>

							<tr v-for="(item , i) in projects" :key="i">
								<td>{{item.id}}</td>
								<td>{{item.user_name}}</td>
								<td>{{item.title}}</td>
								<td>{{item.worth}}</td>
								<td>
									<vf-badge class="bg-orange fg-white" v-if="item.status == 'pending'">
										Pending
									</vf-badge>

									<vf-badge class="bg-green fg-white" v-else-if="item.status == 'accepted'">
										Accepted
									</vf-badge>

									<vf-badge class="bg-red fg-white" v-else-if="item.status == 'rejected'">
										Rejected
									</vf-badge>

									<vf-badge class="bg-blue fg-white" v-else-if="item.status == 'finished'">
										Finished
									</vf-badge>
								</td>
								<td>{{item.human_created_at}}</td>
								<td>
									<vf-btn class="" type="outline" @click="showEditProject(item.id)">Edit <i class="fas fa-pencil ms-2"></i></vf-btn>
									<vf-btn class="ms-3" type="primary" @click="showViewProject(item.id)">View <i class="fas fa-eye ms-2"></i></vf-btn>
								</td>
							</tr>

						</vf-table>
					</div>
				</div>
			</template>

			<EditProject v-if="editProject.show" />
			<ViewProject v-if="viewProject.show" />
		</div>

	</div>
</template>

<script>
import EditProject from './EditProject.vue'
import ViewProject from './ViewProject.vue'

export default {
	components: {
		EditProject,
		ViewProject
	},
	data() {
		return {
			loading: {
				projects: false,
			},
			projects: [],
			editProject: {
				show: false,
				project_id: null,
			},
			viewProject: {
				show: false,
				project_id: null,
			}
		}
	},
	methods: {
		showEditProject(project_id) {
			this.editProject.show = true;
			this.editProject.project_id = project_id;
		},
		showViewProject(project_id) {
			this.viewProject.project_id = project_id;
			this.viewProject.show = true;
		},
		getProjects() {
			/* call the api */
			this.loading.projects = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/projects/getAllProjects", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.projects = result.data.data.projects;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.projects = false;
				})
		}
	},
	created() {
		this.getProjects();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Projects");
	}
}
</script>

<style lang="scss">
</style>