<template>
	<vf-modal class="view-user" v-model="$parent.viewUser.show">
		<button class="close-btn" @click="$parent.viewUser.show = false">
			<i class="fas fa-times"></i>
		</button>

		<div class="view-user-content">

			<vf-card class="">
				<vf-loading class="mx-auto mt-3" v-if="loadingUser" />

				<template v-else>
					<div class="card-profile-avatar">
						<vf-avatar size="xxl">
							<img v-if="user.profile_pic_url" :src="user.profile_pic_url">
							<span v-else-if="user.name">{{ user.name.substring(0,2) }}</span>
						</vf-avatar>
					</div>

					<div class="card-profile-title mt-3">

					</div>

					<div class="card-profile-desc mt-2">
						{{user.email}}
					</div>

					<div class="user-info-wrapper row gy-4 mt-4">
						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Current Wallet: </div>
								<div class="user-info-value">{{user.current_wallet}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Freezed: </div>
								<div class="user-info-value">{{user.freezed_wallet}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Username: </div>
								<div class="user-info-value">{{user.username}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Signup Date: </div>
								<div class="user-info-value">{{user.human_created_at}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Github: </div>
								<div class="user-info-value">{{user.github}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Linkedin: </div>
								<div class="user-info-value">{{user.linkedin}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Instagram: </div>
								<div class="user-info-value">{{user.instagram}}</div>
							</div>
						</div>

						<div class="col-12 col-md-6">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Facebook: </div>
								<div class="user-info-value">{{user.facebook}}</div>
							</div>
						</div>

						<div class="col-12">
							<div class="user-info">
								<div class="user-info-title tg-heading-6">Skills: </div>
								<div class="user-info-value" v-if="user.skills">{{user.skills.join(" / ")}}</div>
							</div>
						</div>
					</div>

					<div class="view-user-btns mt-4">
						<vf-btn class="d-block mx-auto" type="outline" @click="$router.push({ path: '/panel/transactions', query: { user_id: user.id }})">View Transactions</vf-btn>
					</div>
				</template>
			</vf-card>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			loadingUser: false,
			user: {
				id: null,
				email: null,
				username: null,
				name: null,
				password: null,
				skills: null,
				profile_pic: null,
				github: null,
				linkedin: null,
				instagram: null,
				facebook: null,
				current_wallet: null,
				freezed_wallet: null,
				created_at: null,
				token: null,
				suspended: null,
				human_created_at: null,
				profile_pic_url: null,
			}
		}
	},
	methods: {
		getUser() {
			/* call the api */
			this.loadingUser = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", this.$parent.viewUser.user_id);

			/* make call to server */
			this.$http.post("admin/users/getUserById", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.user = result.data.data.user;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loadingUser = false;
				})
		}
	},
	watch: {
		'$parent.viewUser.show': function (newVal) {
			if (newVal == true) {
				this.getUser();
			}
		}
	}
}
</script>

<style lang="scss">
.view-user {
	padding: 0 !important;

	.vf-modal-content-wrapper {
		max-width: 700px !important;
		.vf-modal-content {
			position: relative;
			padding: 0 !important;
		}
	}

	.close-btn {
		background: none;
		position: absolute;
		top: 20px;
		left: 20px;

		i {
			font-size: 16px;
		}
	}

	.view-user-content {
		.user-info-wrapper {
			border-top: 1px solid #eee;

			.user-info {
				display: flex;
				align-items: center;

				.user-info-title {
					white-space: nowrap;
					margin-right: 8px;
				}

				.user-info-value {
					color: rgb(109, 109, 109);
				}
			}
		}
	}
}
</style>