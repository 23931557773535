<template>
	<div class="panel-content personal-data ">
		<div class="personal-data-head">
			<h5 class="personal-data-title tg-heading-5">Personal Data</h5>
		</div>

		<button class="personal-data-avatar mt-4" @click.prevent="$refs.profile_pic_input.click()">
			<vf-avatar size="xxl">
				<template v-if="loading.changeProfilePic">
					<vf-loading :size="20" :thickness="2" />
				</template>

				<template v-else>
					<img v-if="$store.state.Admin.admin_profile_pic_url" :src="$store.state.Admin.admin_profile_pic_url">
					<span v-else>{{ $store.state.Admin.admin_name.substring(0,2) }}</span>
				</template>
			</vf-avatar>

			<div class="avatar-edit-icon">
				<i class="fa fa-pencil"></i>
			</div>
		</button>

		<input class="d-none" ref="profile_pic_input" type="file" @change="handleChangeProfilePic" />

		<vf-loading class="mx-auto my-5" v-if="isPersonalDataLoading"></vf-loading>

		<form class="personal-data-form mt-5" @submit.prevent="updatePersonalData" v-else>
			<vf-input id="name-input" class="mt-3" placeholder="Name" type="text" v-model="personalData.name"></vf-input>
			<vf-btn class="mt-3 save-btn" type="primary" :loading="loading.updatePersonalData">Save Changes</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				changeProfilePic: false,
				personalData: false,
				updatePersonalData: false
			},
			personalData: {
				name: '',
			},
		}
	},
	computed: {
		isPersonalDataLoading(){
			if(this.loading.personalData == true) return true;
			return false;
		}
	},
	methods: {
		handleChangeProfilePic(e) {
			this.loading.changeProfilePic = true;

			var file = e.target.files[0];

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append('profilePic', file);

			this.$http.post('admin/admins/changeProfilePic', data)
				.then((result) => {
					if (result.data.status == "ok") {
						/* update profile pic in store */
						this.$store.commit('updateAdminData', {
							profile_pic_url: result.data.data.admin.profile_pic_url
						});

						/* show message */
						this.$swal({
							icon: "success",
							text: result.data.data.message,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((e) => {
					this.$swal({
						icon: "error",
						text: e,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.changeProfilePic = false;
				});
		},
		getPersonalData() {
			/* call the api */
			this.loading.personalData = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/admins/authToken", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.personalData.name = result.data.data.admin.name;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.personalData = false;
				})
		},
		updatePersonalData() {
			/* call the api */
			this.loading.updatePersonalData = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("name", this.personalData.name);

			/* make call to server */
			this.$http.post("admin/admins/editAdmin", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$swal({
							icon: "success",
							text: result.data.data.message,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});

						this.getPersonalData();

						/* update profile pic in store */
						this.$store.commit('updateAdminData', {
							name: result.data.data.admin.name
						});
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.updatePersonalData = false;
				})
		}
	},
	created() {
		this.getPersonalData();
	}
}
</script>

<style>
</style>