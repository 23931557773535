<template>
	<form class="vf-search-input-form" @submit.prevent="search">
		<div class="vf-input-wrapper">
			<div class="vf-search-input">
				<input type="text" placeholder="Search" v-model="inputVal" />
				<div class="vf-input-icon">
					<i class="fas fa-search"></i>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	props: {
		value: {
			default: ""
		},
	},
	data() {
		return {
			searchQuery: this.value,
		}
	},
	computed: {
		inputVal: {
			get() {
				return this.value;
			},
			set(val) {
				
				this.$emit('input', val);
			}
		}
	},
	methods: {
		search() {
			this.$emit('search', this.searchQuery);
		}
	}
}
</script>

<style lang="scss">
.vf-input-wrapper {
	.vf-search-input {
		position: relative;

		input {
			background: #f4f6f3;
			border-radius: 6px;
			border: 1px solid rgba(0, 99, 247, 0);
			padding: 10px 40px 10px 10px;
			width: 100%;
			transition: all 200ms ease;

			&:focus {
				border-color: #0063f7;
				background-color: #f3f7fd;
			}
		}

		.vf-input-icon {
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);

			i {
				color: #8f90a6;
				font-size: 16px;
			}
		}
	}
}
</style>