<template>
	<div class="panel-content totalAvailableCoins">
		<div class="totalAvailableCoins-head">
			<h5 class="totalAvailableCoins-title tg-heading-5">Available Coins</h5>
		</div>

		<vf-loading class="mx-auto my-5" v-if="loading.totalAvailableCoins" />

		<form class="totalAvailableCoins-form mt-3" @submit.prevent="setTotalAvailableCoins" v-else>
			<vf-input id="coins-amount-input" label="Total Available Coins:" v-model="totalAvailableCoins" />
			<vf-btn class="mt-3 save-btn" type="primary" :disabled="totalAvailableCoins == ''" :loading="loading.setTotalAvailableCoins">Set Data</vf-btn>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				totalAvailableCoins: false,
				setTotalAvailableCoins: false,
			},
			totalAvailableCoins: 0
		}
	},
	methods: {
		getTotalAvailableCoins() {
			/* call the api */
			this.loading.totalAvailableCoins = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/settings/getTotalAvailableCoins", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.totalAvailableCoins = result.data.data.total_available_coins;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.totalAvailableCoins = false;
				})
		},
		setTotalAvailableCoins() {
			/* call the api */
			this.loading.setTotalAvailableCoins = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("coinsAmount", this.totalAvailableCoins);

			/* make call to server */
			this.$http.post("admin/settings/setTotalAvailableCoins", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.getTotalAvailableCoins();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.setTotalAvailableCoins = false;
				})
		}
	},
	created() {
		this.getTotalAvailableCoins();
	}
}
</script>

<style>
</style>