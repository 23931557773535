<template>
	<div class="vf-spacer"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.vf-spacer {
	flex: 1;
}
</style>