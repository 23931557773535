<template>
	<div class="panel-content-wrapper">
		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="loading.withdrawHistory"></vf-loading>

			<div class="panel-content withdraw-history" v-else>
				<div class="withdraw-history-content">
					<vf-table>
						<tr>
							<th>#</th>
							<th>User</th>
							<th>Amount</th>
							<th>Address</th>
							<th>Status</th>
							<th>TXID</th>
							<th style="width: 50px;"></th>
						</tr>

						<tr v-for="(item, i) in withdrawHistory" :key="i">
							<td>{{item.id}}</td>
							<td>{{item.user_name}}</td>
							<td>{{item.amount}}</td>
							<td>{{item.wallet_address}}</td>
							<td>
								<vf-badge class="bg-orange fg-white" v-if="item.status == 'pending'">
									Pending
								</vf-badge>

								<vf-badge class="bg-blue fg-white" v-if="item.status == 'ongoing'">
									Ongoing
								</vf-badge>

								<vf-badge class="bg-green fg-white" v-if="item.status == 'finished'">
									Finished
								</vf-badge>
							</td>
							<td>{{item.txid}}</td>
							<td>
								<vf-btn class="ms-2" type="outline" @click="showEditWithdraw(i)">Edit <i class="fas fa-pencil ms-2"></i></vf-btn>
							</td>
						</tr>

					</vf-table>
				</div>
			</div>
		</div>

		<EditWithdraw />
	</div>
</template>

<script>
import EditWithdraw from "./EditWithdraw"

export default {
	components: {
		EditWithdraw,
	},
	data() {
		return {
			loading: {
				withdrawHistory: false,
			},
			withdrawHistory: [],
			editWithdraw: {
				show: false,
				withdrawData:{
					index: null,
					id: null,
					status: null,
					txid: null
				}
			},
		}
	},
	methods: {
		showEditWithdraw(index) {
			this.editWithdraw.withdrawData.index = index;
			this.editWithdraw.withdrawData.id = this.withdrawHistory[index].id;
			this.editWithdraw.withdrawData.status = this.withdrawHistory[index].status;
			this.editWithdraw.withdrawData.txid = this.withdrawHistory[index].txid;

			this.editWithdraw.show = true
		},
		getWithdrawHistory() {
			/* call the api */
			this.loading.withdrawHistory = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/transactions/getAllWithdraws", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.withdrawHistory = result.data.data.transactions;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.withdrawHistory = false;
				})
		},
	},
	created() {
		this.getWithdrawHistory();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Withdraws");
	}
}
</script>

<style lang="scss">
</style>