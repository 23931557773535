<template>
	<div class="panel-content-wrapper">
		<div class="panel-bar-wrapper users-panel-bar">
			<div class="left">
				<vf-search-input v-model="searchQ" @search="getTransactions" />
			</div>
		</div>

		<div class="panel-content-container">
			<vf-loading class="mx-auto mt-3" v-if="loading.transactions"></vf-loading>

			<div class="panel-content transactions" v-else>
				<div class="transactions-content">
					<vf-table>
						<tr>
							<th>#</th>
							<th>User</th>
							<th>Wallet</th>
							<th>Amount</th>
							<th>Description</th>
							<th>Date</th>
						</tr>

						<tr v-for="(item) in transactions.rows" :key="item.id" :class="{'bg-red-50' : item.amount < 0 ,  'bg-green-50' : item.amount > 0}">
							<td>{{item.id}}</td>
							<td>{{item.user_name}}</td>
							<td>
								<span v-if="item.wallet == 'current_wallet'">Current Wallet</span>
								<span v-else-if="item.wallet == 'freezed_wallet'">Freezed Wallet</span>
							</td>
							<td>{{item.amount}}</td>
							<td>{{item.description}}</td>
							<td>{{item.human_created_at}}</td>
						</tr>

					</vf-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading: {
				transactions: false,
			},
			transactions: {
				"rows": [],
				"total_pages": null,
				"total_count": null
			},
			searchQ: '',
		}
	},
	methods: {
		getTransactions() {
			/* call the api */
			this.loading.transactions = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/transactions/getAllTransactions", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.transactions = result.data.data.transactions;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.transactions = false;
				})
		}
	},
	created() {
		if(this.$route.query.user_id != null){
			this.searchQ = this.$route.query.user_id;
		}

		this.getTransactions();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Transactions");
	}
}
</script>

<style lang="scss">
</style>