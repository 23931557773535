<template>
	<vf-modal class="make-transaction" v-model="$parent.makeTransaction.show">
		<div class="make-transaction-header">
			<button class="close-btn" @click="$parent.makeTransaction.show = false">
				<i class="fas fa-times"></i>
			</button>
			<div class="make-transaction-title">Make Transaction</div>
		</div>

		<div class="make-transaction-content">
			<form class="make-transaction-form" @submit.prevent="makeTransaction">
				<div class="form-row">
					<vf-select-input label="Action" placeholder="Select Action" :selectOptions="actions" v-model="makeTransactionData.action" />
				</div>
				<div class="form-row">
					<vf-input label="Amount" id="make-transaction-amount" type="tel" v-model="makeTransactionData.amount" />
				</div>
				<div class="form-row">
					<vf-input label="Description" id="make-transaction-desc" placeholder="optional" v-model="makeTransactionData.description" />
				</div>
				<div class="form-btns d-flex">
					<vf-btn class="make-transaction-create-btn" type="primary" :disabled="!isDataFilled" :loading="loading.makeTransaction">
						Make Transaction
					</vf-btn>
					<vf-btn class="make-transaction-cancel-btn" type="outline" @click.prevent="$parent.makeTransaction.show = false">
						Cancel
					</vf-btn>
				</div>
			</form>
		</div>
	</vf-modal>
</template>

<script>
export default {
	data() {
		return {
			actions: [
				{ title: 'Change Available Wallet', value: 'current' },
				{ title: 'Change Freezed Wallet', value: 'freezed' },
				{ title: 'Freeze', value: 'freeze' },
				{ title: 'Unfreeze', value: 'unfreeze' },
			],
			loading: {
				makeTransaction: false
			},
			makeTransactionData: {
				action: null,
				amount: '',
				description: '',
			}
		}
	},
	computed: {
		isDataFilled() {
			if (this.makeTransactionData.action == null) return false;
			if (this.makeTransactionData.amount == '') return false;
			return true;
		}
	},
	methods: {
		resetForm() {
			this.makeTransactionData = {
				action: null,
				amount: '',
				description: '',
			}
		},
		makeTransaction() {
			/* call the api */
			this.loading.makeTransaction = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", this.$parent.makeTransaction.user_id);
			data.append("action", this.makeTransactionData.action.value);
			data.append("amount", this.makeTransactionData.amount);
			data.append("description", this.makeTransactionData.description);

			/* make call to server */
			this.$http.post("admin/users/makeTransasction", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.makeTransaction.show = false;
						this.$parent.getUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							showConfirmButton: false,
							position: 'top-end',
							toast: true,
							timer: 3000,
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						showConfirmButton: false,
						position: 'top-end',
						toast: true,
						timer: 3000,
					});
				})
				.finally(() => {
					this.loading.makeTransaction = false;
				})
		}
	},
	watch: {
		'$parent.makeTransaction.show': function (newVal) {
			if (newVal == true) {
				this.resetForm();
			}
		}
	}
}
</script>

<style lang="scss">
.make-transaction {
	.make-transaction-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.make-transaction-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.make-transaction-content {
		margin-top: 24px;

		.make-transaction-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;

			.form-row {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-btns {
				margin-top: 32px;
			}

			.make-transaction-send-btn {
				margin-top: 28px;
			}

			.make-transaction-cancel-btn {
				margin-left: 16px;
				border-color: #4040f2;
				color: #4040f2;
			}
		}
	}
}
</style>